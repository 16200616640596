import { apiPut, apiGet } from '.';

export const putAcceptOffer = async (body) => {
  const url = '/api/v1/accept-offer';
  return apiPut(url, body);
};

export const putCounterOffer = async (body) => {
  const url = '/api/v1/counteroffer';
  return apiPut(url, body);
};

export const getListVoOffers = async () => {
  const url = '/api/v1/list-vo-offers';
  return apiGet(url);
};
