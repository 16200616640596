import { useTheme } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@mui/lab';

import { getCompanyInfo } from 'api/PortalScriptsService';
import Avatar from './@extended/Avatar';
import useAuth from 'hooks/useAuth';

import formattedPrice from 'utils/formatPrice';

export default function PriceHistory({ offerCar }) {
  const [canoInfo, setCanoInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const theme = useTheme();

  useEffect(() => {
    getCompanyInfo({ name: user.companyName })
      .then((res) => {
        setCanoInfo(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  }, [user]);

  const clientConfig = useMemo(
    () => ({
      position: 'right',
      color: 'warning',
      src: user?.avatar
    }),
    [user]
  );

  const canoConfig = useMemo(
    () => ({
      position: 'left',
      color: 'success',
      src: canoInfo?.avatar ? `data:image/png;base64, ${canoInfo?.avatar}` : ''
    }),
    [canoInfo]
  );

  return loading ? (
    <Grid display="flex" justifyContent="center" marginTop={10}>
      <CircularProgress />
    </Grid>
  ) : (
    <Timeline
      position="alternate"
      sx={{
        '& .MuiTimelineItem-root': { minHeight: 90 },
        '& .MuiTimelineOppositeContent-root': { mt: 0.5 },
        '& .MuiTimelineDot-root': {
          borderRadius: 1.25,
          boxShadow: 'none',
          margin: 0,
          ml: 1.25,
          mr: 1.25,
          p: 1,
          '& .MuiSvgIcon-root': { fontSize: '1.2rem' }
        },
        '& .MuiTimelineContent-root': { borderRadius: 1, bgcolor: 'secondary.lighter', height: '100%' },
        '& .MuiTimelineConnector-root': { border: '1px dashed', borderColor: 'secondary.light', bgcolor: 'transparent' }
      }}
    >
      {offerCar?.price_history?.map((message, index) => {
        const config = message.responsible_id === user.company ? clientConfig : canoConfig;
        return (
          <TimelineItem position={config.position} key={message.price + index}>
            <TimelineOppositeContent align={config.position} variant="body2" color="text.secondary">
              {message.date}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot sx={{ color: `${config.color}.main`, bgcolor: `${config.color}.lighter` }}>
                <Avatar alt="Profile" src={config.src} sx={{ width: 35, height: 35, border: '1px dashed' }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ bgcolor: `${theme.palette[config.color].lighter}!important` }}>
              <Typography variant="h6" component="span">
                {message.price ? formattedPrice(message.price) : '-'}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
}

PriceHistory.propTypes = {
  offerCar: PropTypes.object
};
