import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import ListVoOfferCarDetails from 'pages/client/sales/listVoOfferCar/listVoOfferCarDetails';

// pages routing
const Home = Loadable(lazy(() => import('pages/client/home')));
const List = Loadable(lazy(() => import('pages/client/sales/invoice/list')));
const Details = Loadable(lazy(() => import('pages/client/sales/invoice/details')));
const ListVoOfferKanban = Loadable(lazy(() => import('pages/client/sales/listVoOfferKanban')));
const AllAvailableCarsTab = Loadable(lazy(() => import('pages/client/sales/listVoOfferCar/allAvailableCarsTab')));
const AvailableInmediatePurchaseTable = Loadable(lazy(() => import('pages/client/sales/listVoOfferCar/availableInmediatePurchaseTable')));
const AvailableSumissionTable = Loadable(lazy(() => import('pages/client/sales/listVoOfferCar/availableSumission')));
const AvailableFixedPrice = Loadable(lazy(() => import('pages/client/sales/listVoOfferCar/availableFixedPrice')));
const ListVoOfferCarsTable = Loadable(lazy(() => import('pages/client/sales/listVoOfferCar/listVoOfferCarsTable')));
const LoginAsUser = Loadable(lazy(() => import('components/loginAsUser')));

const ClientRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/',
      element: <Home />
    },
    {
      path: 'offers',
      children: [
        {
          path: 'my-offers',
          element: <ListVoOfferKanban />
        },
        {
          path: 'my-offers/:id/cars',
          element: <AvailableInmediatePurchaseTable />
        },
        {
          path: 'my-offers/:id/soumission-cars',
          element: <AvailableSumissionTable />
        },
        {
          path: 'my-offers/:id/fixed-cars',
          element: <AvailableFixedPrice />
        },
        {
          path: 'all-available-cars',
          element: <AllAvailableCarsTab />
        },
        {
          path: 'under-negotiation-cars',
          element: <ListVoOfferCarsTable showButton={true} status={['waiting_for_validation', 'accepted_pending_validation']} />
        },
        {
          path: 'under-negotiation-cars/:id',
          element: <ListVoOfferCarDetails />
        }
      ]
    },
    {
      path: 'orders',
      children: [
        {
          path: 'my-purchases',
          element: <List />
        },
        {
          path: 'my-purchases/:id',
          element: <Details />
        },
        {
          path: 'completed',
          element: <ListVoOfferCarsTable status={['accepted_pending_validation']} />
        }
      ]
    },
    {
      path: '/login-as-user',
      element: <LoginAsUser />
    }
  ]
};

export default ClientRoutes;
