import { lazy } from 'react';

// project import
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';

const Terms = Loadable(lazy(() => import('pages/terms-and-conditions/terms')));
const Privacy = Loadable(lazy(() => import('pages/terms-and-conditions/privacy')));

// ==============================|| AUTH ROUTING ||============================== //

const TermsRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <CommonLayout />,
      children: [
        {
          path: 'terms',
          element: <Terms />
        },
        {
          path: 'privacy',
          element: <Privacy />
        }
      ]
    }
  ]
};

export default TermsRoutes;
