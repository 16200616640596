// third-party
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  userRole: 'info',
  openItems: {
    info: ['contact-us'],
    client: ['home-purchases'],
    provider: ['home-sales']
  },
  selectedID: null,
  drawerOpen: false,
  componentDrawerOpen: true,
  error: null
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      const { userRole, openItem } = action.payload;
      state.openItems[userRole] = openItem;
    },
    activeRole(state, action) {
      state.userRole = action.payload.userRole;
    },
    activeID(state, action) {
      state.selectedID = action.payload;
    },
    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },
    hasError(state, action) {
      state.error = action.payload;
    }
  }
});

export default menu.reducer;

export const { activeItem, activeRole, openDrawer, activeID } = menu.actions;

export { initialState };
