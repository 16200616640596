// third-party
import { useIntl } from 'react-intl';

function useStatusConfig() {
  const intl = useIntl();
  const carOfferStatus = {
    pending_to_prepare: {
      value: 'pending_to_prepare',
      label: intl.formatMessage({ id: 'use-status-config.in-preparation' }),
      color: 'secondary'
    },
    waiting_for_response: {
      value: 'waiting_for_response',
      label: intl.formatMessage({ id: 'use-status-config.available' }),
      color: 'info'
    },
    waiting_for_validation: {
      value: 'waiting_for_validation',
      label: intl.formatMessage({ id: 'use-status-config.analyzing-counteroffer' }),
      style: { color: '#A85EA1' }
    },
    reserved: { value: 'reserved', label: intl.formatMessage({ id: 'use-status-config.reserved' }), style: { color: '#B45EEB' } },
    reserved_by_commercial: {
      value: 'reserved_by_commercial',
      label: intl.formatMessage({ id: 'use-status-config.not-available' }),
      style: { color: '#EB5E85' }
    },
    accepted_pending_validation: {
      value: 'accepted_pending_validation',
      label: intl.formatMessage({ id: 'use-status-config.preparing-your-order' }),
      color: 'success'
    },
    accepted: {
      value: 'accepted',
      label: intl.formatMessage({ id: 'use-status-config.purchased' }),
      color: 'success'
    },
    reject_by_client: { value: 'reject_by_client', label: intl.formatMessage({ id: 'use-status-config.not-available' }), color: 'error' },
    reject_by_commercial: {
      value: 'reject_by_commercial',
      label: intl.formatMessage({ id: 'use-status-config.not-available' }),
      color: 'error'
    },
    reject_by_source: { value: 'reject_by_source', label: intl.formatMessage({ id: 'use-status-config.not-available' }), color: 'error' },
    expired: { value: 'expired', label: intl.formatMessage({ id: 'use-status-config.expired' }), style: { color: '#A51E2C' } }
  };

  const listVoStatus = {
    to_prepare: { label: intl.formatMessage({ id: 'use-status-config.in-preparation' }), color: 'secondary' },
    pricing_purchase: { label: intl.formatMessage({ id: 'use-status-config.in-preparation' }), color: 'secondary' },
    pricing_sale: { label: intl.formatMessage({ id: 'use-status-config.in-preparation' }), color: 'secondary' },
    published: { label: intl.formatMessage({ id: 'use-status-config.active' }), color: 'success' },
    finished: { label: intl.formatMessage({ id: 'use-status-config.expired' }), color: 'warning' }
  };

  const carStatus = {
    on_hold: { value: 'on_hold', label: intl.formatMessage({ id: 'use-status-config.in-preparation' }), color: 'warning' },
    pricing: { value: 'pricing', label: intl.formatMessage({ id: 'use-status-config.in-preparation' }), color: 'warning' },
    available_exclusive: { value: 'available_exclusive', label: intl.formatMessage({ id: 'use-status-config.available' }), color: 'info' },
    available_free: { value: 'available_free', label: intl.formatMessage({ id: 'use-status-config.available' }), color: 'info' },
    reserved: { value: 'reserved', label: intl.formatMessage({ id: 'use-status-config.reserved' }), color: 'success' },
    sold: { value: 'sold', label: intl.formatMessage({ id: 'use-status-config.sold' }), color: 'success' },
    cancelled: { value: 'cancelled', label: intl.formatMessage({ id: 'use-status-config.removed' }), color: 'error' }
  };

  const carOfferStatusOptions = Object.values(carOfferStatus).map((item) => {
    return {
      value: item.value,
      label: item.label
    };
  });

  const carStatusOptions = Object.values(carStatus).map((item) => {
    return {
      value: item.value,
      label: item.label
    };
  });

  const offerStatus = {
    pending_to_be_answered_by_client: {
      value: 'pending_to_be_answered_by_client',
      label: intl.formatMessage({ id: 'use-status-config.available' }),
      color: 'info'
    },
    pending_to_be_validated: {
      value: 'pending_to_be_validated',
      label: intl.formatMessage({ id: 'use-status-config.available' }),
      style: { color: 'info' },
      color: 'info'
    },
    accepted_pending_validation: {
      value: 'accepted_pending_validation',
      label: intl.formatMessage({ id: 'use-status-config.available' }),
      style: { color: 'info' },
      color: 'info'
    },
    accepted: {
      value: 'accepted',
      label: intl.formatMessage({ id: 'use-status-config.available' }),
      style: { color: 'info' },
      color: 'info'
    },
    rejected: { value: 'rejected', label: intl.formatMessage({ id: 'use-status-config.not-available' }), color: 'error' },
    expired: { value: 'expired', label: intl.formatMessage({ id: 'use-status-config.not-available' }), color: 'error' }
  };

  const budgetStatus = {
    pending_to_evaluate: {
      value: 'pending_to_evaluate',
      label: intl.formatMessage({ id: 'use-status-config.pending-to-evaluate' }),
      color: 'warning'
    },
    pending_to_be_accepted_by_client: {
      value: 'pending_to_be_accepted_by_client',
      label: intl.formatMessage({ id: 'use-status-config.pending-to-be-accepted' }),
      color: 'primary'
    },
    under_negotiation: {
      value: 'under_negotiation',
      label: intl.formatMessage({ id: 'use-status-config.under-negotiation' }),
      color: 'info'
    },
    in_preparation: {
      value: 'in_preparation',
      label: intl.formatMessage({ id: 'use-status-config.in-preparation' }),
      style: { color: '#A85EA1' }
    },
    confirmed: {
      value: 'confirmed',
      label: intl.formatMessage({ id: 'use-status-config.confirmed' }),
      color: 'success'
    },
    rejected_by_transport_agent: {
      value: 'rejected_by_transport_agent',
      label: intl.formatMessage({ id: 'use-status-config.rejected' }),
      color: 'error'
    },
    rejected_by_client: {
      value: 'rejected_by_client',
      label: intl.formatMessage({ id: 'use-status-config.rejected' }),
      color: 'error'
    }
  };

  const budgetStatusOptions = Object.values(budgetStatus).map((item) => {
    return {
      value: item.value,
      label: item.label
    };
  });

  const saleOperationStatus = {
    pending_to_generate_bdc: {
      value: 'pending_to_generate_bdc',
      label: intl.formatMessage({ id: 'use-status-config.in-preparation' }),
      color: 'warning'
    },
    pending_to_send_bdc: {
      value: 'pending_to_send_bdc',
      label: intl.formatMessage({ id: 'use-status-config.in-preparation' }),
      color: 'warning'
    },
    pending_to_receive_client_payment: {
      value: 'pending_to_receive_client_payment',
      label: intl.formatMessage({ id: 'use-status-config.in-preparation' }),
      color: 'warning'
    },
    pending_to_generate_BDS: {
      value: 'pending_to_generate_BDS',
      label: intl.formatMessage({ id: 'use-status-config.in-preparation' }),
      color: 'warning'
    },
    pending_to_send_documents_to_client: {
      value: 'pending_to_send_documents_to_client',
      label: intl.formatMessage({ id: 'use-status-config.in-preparation' }),
      color: 'warning'
    },
    completed: {
      value: 'completed',
      label: intl.formatMessage({ id: 'use-status-config.completed' }),
      color: 'success'
    },
    cancelled_by_client: {
      value: 'cancelled_by_client',
      label: intl.formatMessage({ id: 'use-status-config.cancelled' }),
      color: 'error'
    },
    cancelled_by_provider: {
      value: 'cancelled_by_provider',
      label: intl.formatMessage({ id: 'use-status-config.cancelled' }),
      color: 'error'
    },
    cancellation_completed: {
      value: 'cancellation_completed',
      label: intl.formatMessage({ id: 'use-status-config.removed' }),
      color: 'secondary'
    }
  };

  const saleOperationStatusOptions = Object.values(saleOperationStatus).map((item) => {
    return {
      value: item.value,
      label: item.label
    };
  });

  const invoiceStatus = {
    pending: {
      value: 'pending',
      label: intl.formatMessage({ id: 'use-status-config.pending' }),
      color: 'warning'
    },
    paid: { value: 'paid', label: intl.formatMessage({ id: 'use-status-config.paid' }), color: 'success' },
    cancelled: {
      value: 'cancelled',
      label: intl.formatMessage({ id: 'use-status-config.cancelled' }),
      color: 'error'
    },
    done: { value: 'done', label: intl.formatMessage({ id: 'use-status-config.done' }), color: 'info' }
  };

  const invoiceStatusOptions = Object.values(invoiceStatus).map((item) => {
    return {
      value: item.value,
      label: item.label
    };
  });

  const truckStatus = {
    pending_to_assign_plate_number: {
      value: 'pending_to_assign_plate_number',
      label: intl.formatMessage({ id: 'use-status-config.in-preparation' }),
      color: 'warning'
    },
    litigation: {
      value: 'litigation',
      label: intl.formatMessage({ id: 'use-status-config.litigation' }),
      color: 'primary'
    },
    in_transit: {
      value: 'in_transit',
      label: intl.formatMessage({ id: 'use-status-config.in-transit' }),
      color: 'info'
    },
    pending_to_receive_cmr: {
      value: 'pending_to_receive_cmr',
      label: intl.formatMessage({ id: 'use-status-config.pending-to-receive-cmr' }),
      style: { color: '#A51E2C' }
    },
    pending_bill_validation: {
      value: 'pending_bill_validation',
      label: intl.formatMessage({ id: 'use-status-config.pending-to-be-completed' }),
      color: 'warning'
    },
    completed: {
      value: 'completed',
      label: intl.formatMessage({ id: 'use-status-config.completed' }),
      color: 'success'
    }
  };
  const truckStatusOptions = Object.values(truckStatus).map((item) => {
    return {
      value: item.value,
      label: item.label
    };
  });

  const listVoNatureConfig = {
    immediate_sale: {
      label: intl.formatMessage({ id: 'use-status-config.immediate-sale' }),
      color: 'primary',
      style: { backgroundColor: 'white', color: '#F26F14', borderColor: '#F26F14', fontStyle: 'bold' }
    },
    auction: {
      label: intl.formatMessage({ id: 'use-status-config.soumission' }),
      color: 'info',
      style: { backgroundColor: 'white', color: '#10A7E4', borderColor: '#10A7E4', fontStyle: 'bold' }
    },
    fixed_price: {
      label: intl.formatMessage({ id: 'use-status-config.fixed-price' }),
      color: 'success',
      style: { backgroundColor: 'white', color: '#10CA25', borderColor: '#10CA25', fontStyle: 'bold' }
    }
  };

  const proformaStatus = {
    all: { label: intl.formatMessage({ id: 'use-status-config.all' }), color: 'primary' },
    paid: { label: intl.formatMessage({ id: 'use-status-config.paid' }), color: 'success' },
    signed: { label: intl.formatMessage({ id: 'use-status-config.signed' }), color: 'warning' },
    pending: { label: intl.formatMessage({ id: 'use-status-config.pending' }), color: 'warning' },
    cancelled: { label: intl.formatMessage({ id: 'use-status-config.cancelled' }), color: 'error' }
  };

  return {
    budgetStatus,
    budgetStatusOptions,
    carStatus,
    carStatusOptions,
    carOfferStatus,
    carOfferStatusOptions,
    invoiceStatus,
    invoiceStatusOptions,
    saleOperationStatus,
    saleOperationStatusOptions,
    truckStatus,
    truckStatusOptions,
    listVoStatus,
    offerStatus,
    listVoNatureConfig,
    proformaStatus
  };
}

export { useStatusConfig };
