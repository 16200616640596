/* eslint-disable no-unused-vars */

import { useMemo } from 'react';

// material-ui
import { Box, useMediaQuery } from '@mui/material';

// project import
import Search from './Search';
import Application from './Application';
import Profile from './Profile';
import Localization from './Localization';
import Notification from './Notification';
import ShoppingCart from './ShoppingCart';
import Customization from './Customization';
import MobileSection from './MobileSection';

import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';
import useAuth from 'hooks/useAuth';

import { LAYOUT_CONST } from 'config';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { i18n, menuOrientation } = useConfig();
  const { user } = useAuth();
  const shoppingCartActive = localStorage.getItem('shoppingCart');
  const downLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const localization = useMemo(() => <Localization />, [i18n]);

  return (
    <>
      {menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG && <DrawerHeader open={true} />}
      {!downLG && <Search />}
      {!downLG && localization}

      {downLG && <Box sx={{ width: '100%', ml: 1 }} />}
      {shoppingCartActive && <ShoppingCart />}
      {user.tags.length > 1 && <Application />}
      <Notification />
      {user?.tags.includes('development') && <Customization />}
      {!downLG && <Profile />}
      {downLG && <MobileSection />}
    </>
  );
};

export default HeaderContent;
