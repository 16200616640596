import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

// material-ui
import { Grid, Stack } from '@mui/material';

// project import
import { getListVoOfferCar } from 'api/ListVoOfferCarService';
import OfferCarFeatures from './OfferCarFeatures';
import MainCard from 'components/MainCard';
import PriceHistory from 'components/PriceHistory';
import SimpleBar from 'simplebar-react';
import { FormattedMessage } from 'react-intl';

// ==============================|| Offer Car Details ||============================== //
const ListVoOfferCarDetails = () => {
  const { id } = useParams();
  const [offerCar, setOfferCar] = useState();

  const fetchData = useCallback(async () => {
    const res = await getListVoOfferCar(id);
    setOfferCar(res.data.data);
  }, [id]);

  useEffect(() => fetchData(), [fetchData]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} xl={6}>
          <MainCard title={<FormattedMessage id="list-vo-offer-car.offr" />} sx={{ height: '70vh' }}>
            <OfferCarFeatures offerCar={offerCar} />
          </MainCard>
        </Grid>
        <Grid item xs={12} md={8} xl={6} sx={{ position: 'relative' }}>
          <MainCard
            title="Price History"
            sx={{
              height: '70vh',
              position: { xs: 'relative', md: 'absolute' },
              top: '16px',
              left: { xs: 0, md: 16 },
              right: 0
            }}
            content={false}
          >
            <SimpleBar
              style={{ height: '90%', overflow: 'scroll' }}
              sx={{ height: { xs: '100%', md: 'calc(100% - 62px)' }, overflow: 'scroll' }}
            >
              <Grid item>
                <Stack>
                  <PriceHistory offerCar={offerCar} />
                </Stack>
              </Grid>
            </SimpleBar>
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

ListVoOfferCarDetails.propTypes = {
  id: PropTypes.string,
  status: PropTypes.array,
  row: PropTypes.object,
  showButton: PropTypes.bool
};

export default ListVoOfferCarDetails;
