import { apiGet, apiPut } from '.';

export const getListVoOfferCars = async (id) => {
  const url = `/api/v1/list-vo-offers/${id}/cars`;
  return apiGet(url);
};

export const getListVoOfferCar = async (id) => {
  const url = `/api/v1/list-vo-offer-car/${id}`;
  return apiGet(url);
};

export const getAllListVoOfferCars = async () => {
  const url = `/api/v1/list-vo-offer-cars`;
  return apiGet(url);
};

export const getAllListVoOfferCarsByProperties = async (properties) => {
  const url = '/api/v1/list-vo-offer-cars';

  const queryParams = new URLSearchParams(properties);

  return apiGet(`${url}?${queryParams.toString()}`);
};

export const getAllListVoOfferCarsByStatus = async (status) => {
  const url = `/api/v1/list-vo-offer-cars?status=${status.join(',')}`;
  return apiGet(url);
};

export const putListVoOfferCar = async (body, id) => {
  const url = `/api/v1/list-vo-offer-car/${id}`;
  return apiPut(url, body);
};
