// third-party
import { useIntl } from 'react-intl';

function useCountryConfig() {
  const intl = useIntl();

  const languageConfig = {
    fr: { value: 'fr_FR' },
    en: { value: 'en_US' },
    es: { value: 'es_ES' }
  };
  const countriesConfig = {
    AD: { label: intl.formatMessage({ id: 'countries.AD' }) },
    AE: { label: intl.formatMessage({ id: 'countries.AE' }) },
    AF: { label: intl.formatMessage({ id: 'countries.AF' }) },
    AG: { label: intl.formatMessage({ id: 'countries.AG' }) },
    AI: { label: intl.formatMessage({ id: 'countries.AI' }) },
    AL: { label: intl.formatMessage({ id: 'countries.AL' }) },
    AM: { label: intl.formatMessage({ id: 'countries.AM' }) },
    AO: { label: intl.formatMessage({ id: 'countries.AO' }) },
    AQ: { label: intl.formatMessage({ id: 'countries.AQ' }) },
    AR: { label: intl.formatMessage({ id: 'countries.AR' }) },
    AS: { label: intl.formatMessage({ id: 'countries.AS' }) },
    AT: { label: intl.formatMessage({ id: 'countries.AT' }) },
    AU: { label: intl.formatMessage({ id: 'countries.AU' }) },
    AW: { label: intl.formatMessage({ id: 'countries.AW' }) },
    AX: { label: intl.formatMessage({ id: 'countries.AX' }) },
    AZ: { label: intl.formatMessage({ id: 'countries.AZ' }) },
    BA: { label: intl.formatMessage({ id: 'countries.BA' }) },
    BB: { label: intl.formatMessage({ id: 'countries.BB' }) },
    BD: { label: intl.formatMessage({ id: 'countries.BD' }) },
    BE: { label: intl.formatMessage({ id: 'countries.BE' }) },
    BF: { label: intl.formatMessage({ id: 'countries.BF' }) },
    BG: { label: intl.formatMessage({ id: 'countries.BG' }) },
    BH: { label: intl.formatMessage({ id: 'countries.BH' }) },
    BI: { label: intl.formatMessage({ id: 'countries.BI' }) },
    BJ: { label: intl.formatMessage({ id: 'countries.BJ' }) },
    BL: { label: intl.formatMessage({ id: 'countries.BL' }) },
    BM: { label: intl.formatMessage({ id: 'countries.BM' }) },
    BN: { label: intl.formatMessage({ id: 'countries.BN' }) },
    BO: { label: intl.formatMessage({ id: 'countries.BO' }) },
    BR: { label: intl.formatMessage({ id: 'countries.BR' }) },
    BS: { label: intl.formatMessage({ id: 'countries.BS' }) },
    BT: { label: intl.formatMessage({ id: 'countries.BT' }) },
    BV: { label: intl.formatMessage({ id: 'countries.BV' }) },
    BW: { label: intl.formatMessage({ id: 'countries.BW' }) },
    BY: { label: intl.formatMessage({ id: 'countries.BY' }) },
    BZ: { label: intl.formatMessage({ id: 'countries.BZ' }) },
    CA: { label: intl.formatMessage({ id: 'countries.CA' }) },
    CC: { label: intl.formatMessage({ id: 'countries.CC' }) },
    CD: { label: intl.formatMessage({ id: 'countries.CD' }) },
    CF: { label: intl.formatMessage({ id: 'countries.CF' }) },
    CG: { label: intl.formatMessage({ id: 'countries.CG' }) },
    CH: { label: intl.formatMessage({ id: 'countries.CH' }) },
    CI: { label: intl.formatMessage({ id: 'countries.CI' }) },
    CK: { label: intl.formatMessage({ id: 'countries.CK' }) },
    CL: { label: intl.formatMessage({ id: 'countries.CL' }) },
    CM: { label: intl.formatMessage({ id: 'countries.CM' }) },
    CN: { label: intl.formatMessage({ id: 'countries.CN' }) },
    CO: { label: intl.formatMessage({ id: 'countries.CO' }) },
    CR: { label: intl.formatMessage({ id: 'countries.CR' }) },
    CU: { label: intl.formatMessage({ id: 'countries.CU' }) },
    CV: { label: intl.formatMessage({ id: 'countries.CV' }) },
    CW: { label: intl.formatMessage({ id: 'countries.CW' }) },
    CX: { label: intl.formatMessage({ id: 'countries.CX' }) },
    CY: { label: intl.formatMessage({ id: 'countries.CY' }) },
    CZ: { label: intl.formatMessage({ id: 'countries.CZ' }) },
    DE: { label: intl.formatMessage({ id: 'countries.DE' }) },
    DJ: { label: intl.formatMessage({ id: 'countries.DJ' }) },
    DK: { label: intl.formatMessage({ id: 'countries.DK' }) },
    DM: { label: intl.formatMessage({ id: 'countries.DM' }) },
    DO: { label: intl.formatMessage({ id: 'countries.DO' }) },
    DZ: { label: intl.formatMessage({ id: 'countries.DZ' }) },
    EC: { label: intl.formatMessage({ id: 'countries.EC' }) },
    EE: { label: intl.formatMessage({ id: 'countries.EE' }) },
    EG: { label: intl.formatMessage({ id: 'countries.EG' }) },
    EH: { label: intl.formatMessage({ id: 'countries.EH' }) },
    ER: { label: intl.formatMessage({ id: 'countries.ER' }) },
    ES: { label: intl.formatMessage({ id: 'countries.ES' }) },
    ET: { label: intl.formatMessage({ id: 'countries.ET' }) },
    FI: { label: intl.formatMessage({ id: 'countries.FI' }) },
    FJ: { label: intl.formatMessage({ id: 'countries.FJ' }) },
    FK: { label: intl.formatMessage({ id: 'countries.FK' }) },
    FM: { label: intl.formatMessage({ id: 'countries.FM' }) },
    FO: { label: intl.formatMessage({ id: 'countries.FO' }) },
    FR: { label: intl.formatMessage({ id: 'countries.FR' }) },
    GA: { label: intl.formatMessage({ id: 'countries.GA' }) },
    GB: { label: intl.formatMessage({ id: 'countries.GB' }) },
    GD: { label: intl.formatMessage({ id: 'countries.GD' }) },
    GE: { label: intl.formatMessage({ id: 'countries.GE' }) },
    GF: { label: intl.formatMessage({ id: 'countries.GF' }) },
    GG: { label: intl.formatMessage({ id: 'countries.GG' }) },
    GH: { label: intl.formatMessage({ id: 'countries.GH' }) },
    GI: { label: intl.formatMessage({ id: 'countries.GI' }) },
    GL: { label: intl.formatMessage({ id: 'countries.GL' }) },
    GM: { label: intl.formatMessage({ id: 'countries.GM' }) },
    GN: { label: intl.formatMessage({ id: 'countries.GN' }) },
    GP: { label: intl.formatMessage({ id: 'countries.GP' }) },
    GQ: { label: intl.formatMessage({ id: 'countries.GQ' }) },
    GR: { label: intl.formatMessage({ id: 'countries.GR' }) },
    GS: { label: intl.formatMessage({ id: 'countries.GS' }) },
    GT: { label: intl.formatMessage({ id: 'countries.GT' }) },
    GU: { label: intl.formatMessage({ id: 'countries.GU' }) },
    GW: { label: intl.formatMessage({ id: 'countries.GW' }) },
    GY: { label: intl.formatMessage({ id: 'countries.GY' }) },
    HK: { label: intl.formatMessage({ id: 'countries.HK' }) },
    HM: { label: intl.formatMessage({ id: 'countries.HM' }) },
    HN: { label: intl.formatMessage({ id: 'countries.HN' }) },
    HR: { label: intl.formatMessage({ id: 'countries.HR' }) },
    HT: { label: intl.formatMessage({ id: 'countries.HT' }) },
    HU: { label: intl.formatMessage({ id: 'countries.HU' }) },
    ID: { label: intl.formatMessage({ id: 'countries.ID' }) },
    IE: { label: intl.formatMessage({ id: 'countries.IE' }) },
    IL: { label: intl.formatMessage({ id: 'countries.IL' }) },
    IM: { label: intl.formatMessage({ id: 'countries.IM' }) },
    IN: { label: intl.formatMessage({ id: 'countries.IN' }) },
    IO: { label: intl.formatMessage({ id: 'countries.IO' }) },
    IQ: { label: intl.formatMessage({ id: 'countries.IQ' }) },
    IR: { label: intl.formatMessage({ id: 'countries.IR' }) },
    IS: { label: intl.formatMessage({ id: 'countries.IS' }) },
    IT: { label: intl.formatMessage({ id: 'countries.IT' }) },
    JE: { label: intl.formatMessage({ id: 'countries.JE' }) },
    JM: { label: intl.formatMessage({ id: 'countries.JM' }) },
    JO: { label: intl.formatMessage({ id: 'countries.JO' }) },
    JP: { label: intl.formatMessage({ id: 'countries.JP' }) },
    KE: { label: intl.formatMessage({ id: 'countries.KE' }) },
    KG: { label: intl.formatMessage({ id: 'countries.KG' }) },
    KH: { label: intl.formatMessage({ id: 'countries.KH' }) },
    KI: { label: intl.formatMessage({ id: 'countries.KI' }) },
    KM: { label: intl.formatMessage({ id: 'countries.KM' }) },
    KN: { label: intl.formatMessage({ id: 'countries.KN' }) },
    KP: { label: intl.formatMessage({ id: 'countries.KP' }) },
    KR: { label: intl.formatMessage({ id: 'countries.KR' }) },
    KW: { label: intl.formatMessage({ id: 'countries.KW' }) },
    KY: { label: intl.formatMessage({ id: 'countries.KY' }) },
    KZ: { label: intl.formatMessage({ id: 'countries.KZ' }) },
    LA: { label: intl.formatMessage({ id: 'countries.LA' }) },
    LB: { label: intl.formatMessage({ id: 'countries.LB' }) },
    LC: { label: intl.formatMessage({ id: 'countries.LC' }) },
    LI: { label: intl.formatMessage({ id: 'countries.LI' }) },
    LK: { label: intl.formatMessage({ id: 'countries.LK' }) },
    LR: { label: intl.formatMessage({ id: 'countries.LR' }) },
    LS: { label: intl.formatMessage({ id: 'countries.LS' }) },
    LT: { label: intl.formatMessage({ id: 'countries.LT' }) },
    LU: { label: intl.formatMessage({ id: 'countries.LU' }) },
    LV: { label: intl.formatMessage({ id: 'countries.LV' }) },
    LY: { label: intl.formatMessage({ id: 'countries.LY' }) },
    MA: { label: intl.formatMessage({ id: 'countries.MA' }) },
    MC: { label: intl.formatMessage({ id: 'countries.MC' }) },
    MD: { label: intl.formatMessage({ id: 'countries.MD' }) },
    ME: { label: intl.formatMessage({ id: 'countries.ME' }) },
    MF: { label: intl.formatMessage({ id: 'countries.MF' }) },
    MG: { label: intl.formatMessage({ id: 'countries.MG' }) },
    MH: { label: intl.formatMessage({ id: 'countries.MH' }) },
    MK: { label: intl.formatMessage({ id: 'countries.MK' }) },
    ML: { label: intl.formatMessage({ id: 'countries.ML' }) },
    MM: { label: intl.formatMessage({ id: 'countries.MM' }) },
    MN: { label: intl.formatMessage({ id: 'countries.MN' }) },
    MO: { label: intl.formatMessage({ id: 'countries.MO' }) },
    MP: { label: intl.formatMessage({ id: 'countries.MP' }) },
    MQ: { label: intl.formatMessage({ id: 'countries.MQ' }) },
    MR: { label: intl.formatMessage({ id: 'countries.MR' }) },
    MS: { label: intl.formatMessage({ id: 'countries.MS' }) },
    MT: { label: intl.formatMessage({ id: 'countries.MT' }) },
    MU: { label: intl.formatMessage({ id: 'countries.MU' }) },
    MV: { label: intl.formatMessage({ id: 'countries.MV' }) },
    MW: { label: intl.formatMessage({ id: 'countries.MW' }) },
    MX: { label: intl.formatMessage({ id: 'countries.MX' }) },
    MY: { label: intl.formatMessage({ id: 'countries.MY' }) },
    MZ: { label: intl.formatMessage({ id: 'countries.MZ' }) },
    NA: { label: intl.formatMessage({ id: 'countries.NA' }) },
    NC: { label: intl.formatMessage({ id: 'countries.NC' }) },
    NE: { label: intl.formatMessage({ id: 'countries.NE' }) },
    NF: { label: intl.formatMessage({ id: 'countries.NF' }) },
    NG: { label: intl.formatMessage({ id: 'countries.NG' }) },
    NI: { label: intl.formatMessage({ id: 'countries.NI' }) },
    NL: { label: intl.formatMessage({ id: 'countries.NL' }) },
    NO: { label: intl.formatMessage({ id: 'countries.NO' }) },
    NP: { label: intl.formatMessage({ id: 'countries.NP' }) },
    NR: { label: intl.formatMessage({ id: 'countries.NR' }) },
    NU: { label: intl.formatMessage({ id: 'countries.NU' }) },
    NZ: { label: intl.formatMessage({ id: 'countries.NZ' }) },
    OM: { label: intl.formatMessage({ id: 'countries.OM' }) },
    PA: { label: intl.formatMessage({ id: 'countries.PA' }) },
    PE: { label: intl.formatMessage({ id: 'countries.PE' }) },
    PF: { label: intl.formatMessage({ id: 'countries.PF' }) },
    PG: { label: intl.formatMessage({ id: 'countries.PG' }) },
    PH: { label: intl.formatMessage({ id: 'countries.PH' }) },
    PK: { label: intl.formatMessage({ id: 'countries.PK' }) },
    PL: { label: intl.formatMessage({ id: 'countries.PL' }) },
    PM: { label: intl.formatMessage({ id: 'countries.PM' }) },
    PN: { label: intl.formatMessage({ id: 'countries.PN' }) },
    PR: { label: intl.formatMessage({ id: 'countries.PR' }) },
    PS: { label: intl.formatMessage({ id: 'countries.PS' }) },
    PT: { label: intl.formatMessage({ id: 'countries.PT' }) },
    PW: { label: intl.formatMessage({ id: 'countries.PW' }) },
    PY: { label: intl.formatMessage({ id: 'countries.PY' }) },
    QA: { label: intl.formatMessage({ id: 'countries.QA' }) },
    RE: { label: intl.formatMessage({ id: 'countries.RE' }) },
    RO: { label: intl.formatMessage({ id: 'countries.RO' }) },
    RS: { label: intl.formatMessage({ id: 'countries.RS' }) },
    RU: { label: intl.formatMessage({ id: 'countries.RU' }) },
    RW: { label: intl.formatMessage({ id: 'countries.RW' }) },
    SA: { label: intl.formatMessage({ id: 'countries.SA' }) },
    SB: { label: intl.formatMessage({ id: 'countries.SB' }) },
    SC: { label: intl.formatMessage({ id: 'countries.SC' }) },
    SD: { label: intl.formatMessage({ id: 'countries.SD' }) },
    SE: { label: intl.formatMessage({ id: 'countries.SE' }) },
    SG: { label: intl.formatMessage({ id: 'countries.SG' }) },
    SH: { label: intl.formatMessage({ id: 'countries.SH' }) },
    SI: { label: intl.formatMessage({ id: 'countries.SI' }) },
    SJ: { label: intl.formatMessage({ id: 'countries.SJ' }) },
    SK: { label: intl.formatMessage({ id: 'countries.SK' }) },
    SL: { label: intl.formatMessage({ id: 'countries.SL' }) },
    SM: { label: intl.formatMessage({ id: 'countries.SM' }) },
    SN: { label: intl.formatMessage({ id: 'countries.SN' }) },
    SO: { label: intl.formatMessage({ id: 'countries.SO' }) },
    SR: { label: intl.formatMessage({ id: 'countries.SR' }) },
    SS: { label: intl.formatMessage({ id: 'countries.SS' }) },
    ST: { label: intl.formatMessage({ id: 'countries.ST' }) },
    SV: { label: intl.formatMessage({ id: 'countries.SV' }) },
    SX: { label: intl.formatMessage({ id: 'countries.SX' }) },
    SY: { label: intl.formatMessage({ id: 'countries.SY' }) },
    SZ: { label: intl.formatMessage({ id: 'countries.SZ' }) },
    TC: { label: intl.formatMessage({ id: 'countries.TC' }) },
    TD: { label: intl.formatMessage({ id: 'countries.TD' }) },
    TF: { label: intl.formatMessage({ id: 'countries.TF' }) },
    TG: { label: intl.formatMessage({ id: 'countries.TG' }) },
    TH: { label: intl.formatMessage({ id: 'countries.TH' }) },
    TJ: { label: intl.formatMessage({ id: 'countries.TJ' }) },
    TK: { label: intl.formatMessage({ id: 'countries.TK' }) },
    TL: { label: intl.formatMessage({ id: 'countries.TL' }) },
    TM: { label: intl.formatMessage({ id: 'countries.TM' }) },
    TN: { label: intl.formatMessage({ id: 'countries.TN' }) },
    TO: { label: intl.formatMessage({ id: 'countries.TO' }) },
    TR: { label: intl.formatMessage({ id: 'countries.TR' }) },
    TT: { label: intl.formatMessage({ id: 'countries.TT' }) },
    TV: { label: intl.formatMessage({ id: 'countries.TV' }) },
    TW: { label: intl.formatMessage({ id: 'countries.TW' }) },
    TZ: { label: intl.formatMessage({ id: 'countries.TZ' }) },
    UA: { label: intl.formatMessage({ id: 'countries.UA' }) },
    UG: { label: intl.formatMessage({ id: 'countries.UG' }) },
    US: { label: intl.formatMessage({ id: 'countries.US' }) },
    UY: { label: intl.formatMessage({ id: 'countries.UY' }) },
    UZ: { label: intl.formatMessage({ id: 'countries.UZ' }) },
    VA: { label: intl.formatMessage({ id: 'countries.VA' }) },
    VC: { label: intl.formatMessage({ id: 'countries.VC' }) },
    VE: { label: intl.formatMessage({ id: 'countries.VE' }) },
    VG: { label: intl.formatMessage({ id: 'countries.VG' }) },
    VI: { label: intl.formatMessage({ id: 'countries.VI' }) },
    VN: { label: intl.formatMessage({ id: 'countries.VN' }) },
    VU: { label: intl.formatMessage({ id: 'countries.VU' }) },
    WF: { label: intl.formatMessage({ id: 'countries.WF' }) },
    WS: { label: intl.formatMessage({ id: 'countries.WS' }) },
    XK: { label: intl.formatMessage({ id: 'countries.XK' }) },
    YE: { label: intl.formatMessage({ id: 'countries.YE' }) },
    YT: { label: intl.formatMessage({ id: 'countries.YT' }) },
    ZA: { label: intl.formatMessage({ id: 'countries.ZA' }) },
    ZM: { label: intl.formatMessage({ id: 'countries.ZM' }) },
    ZW: { label: intl.formatMessage({ id: 'countries.ZW' }) }
  };

  return {
    countriesConfig,
    languageConfig
  };
}

export { useCountryConfig };
