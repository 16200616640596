import React from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Badge, Popover } from '@mui/material';
import { usePopupState, bindPopover, bindHover } from 'material-ui-popup-state/hooks';

// assets
import { ShoppingCartOutlined } from '@ant-design/icons';

// project import
import IconButton from 'components/@extended/IconButton';
import AcceptOfferDialog from 'components/dialogs/client/AcceptOfferDialog';

// ==============================|| HEADER CONTENT - LOCALIZATION ||============================== //

const ShoppingCart = () => {
  const theme = useTheme();
  const cart = useSelector((state) => state.cart);

  const popupState = usePopupState({ variant: 'popover', popupId: 'cart-popover' });
  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        {...bindHover(popupState)}
        color="secondary"
        variant="light"
        sx={{
          color: 'text.primary',
          bgcolor: popupState.isOpen ? iconBackColorOpen : iconBackColor
        }}
      >
        <Badge badgeContent={cart.checkout.products.length} color="error">
          <ShoppingCartOutlined />
        </Badge>
      </IconButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <AcceptOfferDialog
          maxWidth="sm"
          fullWidth
          isShoppingCart={true}
          listVoCarsData={cart.checkout.products}
          sx={{ '& .MuiDialog-paper': { p: 0 } }}
        />
      </Popover>
    </Box>
  );
};

export default ShoppingCart;
