import { apiGet, apiPost, apiPut } from '.';

export const getUser = async () => {
  const url = '/api/v1/users';

  const res = await apiGet(url);
  return res;
};

export const putUser = async (id, body) => {
  const url = `/api/v1/users/${id}`;
  return apiPut(url, body);
};

export const logout = async () => {
  const url = '/api/v1/users/logout';
  apiPost(url);
};

export const login = async (auth) => {
  const url = '/api/v1/users/login';
  const body = {
    auth: {
      username: auth.username,
      password: auth.password
    }
  };

  return apiPost(url, body);
};

export const checkUsername = async (username) => {
  const url = `/api/v1/users/forget-password?username=${username}`;
  const res = await apiGet(url, {}, false);
  return res;
};

export const resetPassword = async (id, data) => {
  const url = `/api/v1/users/${id}/reset-password`;
  const res = await apiPut(url, data, {}, false);
  return res;
};

export const setPassword = async (id, data) => {
  const url = `/api/v1/users/${id}/set-password`;
  const res = await apiPut(url, data, {}, false);
  return res;
};

export const getUserLanguage = async () => {
  const url = '/api/v1/users/lang';

  const res = await apiGet(url);
  return res;
};

export const putUserLanguage = async (data) => {
  const url = '/api/v1/users/lang';

  const res = await apiPut(url, data);
  return res;
};

export const getAddresses = async () => {
  const url = `/api/v1/users/addresses`;

  const res = await apiGet(url);
  return res;
};

export const createAddress = async (data) => {
  const url = '/api/v1/users/addresses';
  const res = await apiPost(url, data);
  return res;
};

export const putAddress = async (id, data) => {
  const url = `/api/v1/users/addresses/${id}`;
  const res = await apiPut(url, data);
  return res;
};

export const loginAsUser = async (partner_id, data) => {
  const url = `/api/v1/users/login-as-user?partner_id=${partner_id}`;
  return apiGet(url, data);
};
