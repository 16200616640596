// material-ui
import { Chip, Grid, Typography } from '@mui/material';
import { useStatusConfig } from 'hooks/useStatusConfig';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import formatKilometers from 'utils/formatKms';
import formattedPrice from 'utils/formatPrice';

// ==============================|| PRODUCT DETAILS - FEATURES ||============================== //

function OfferCarFeatures({ offerCar }) {
  const { carOfferStatus } = useStatusConfig();

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Typography color="textSecondary">
          <FormattedMessage id="offer-car-features.status" />:
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Chip
          color={carOfferStatus[offerCar?.status]?.color && `${carOfferStatus[offerCar?.status]?.color}`}
          label={`${carOfferStatus[offerCar?.status]?.label}`}
          style={carOfferStatus[offerCar?.status]?.style && carOfferStatus[offerCar?.status]?.style}
          size="small"
          variant="light"
        />
      </Grid>

      <Grid item xs={3}>
        <Typography color="textSecondary">
          <FormattedMessage id="offer-car-features.brand" />:
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>{offerCar?.brand || ''}</Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography color="textSecondary">
          <FormattedMessage id="offer-car-features.model" />:
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>{offerCar?.model || ''}</Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography color="textSecondary">
          <FormattedMessage id="offer-car-features.version" />:
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>{offerCar?.version || ''}</Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography color="textSecondary">
          <FormattedMessage id="offer-car-features.matriculation-date" />:
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>{offerCar?.matriculation_date || ''}</Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography color="textSecondary">
          <FormattedMessage id="offer-car-features.damage" />s :
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>{offerCar?.damage_estimated_costs ? formattedPrice(offerCar?.damage_estimated_costs) : '-'}</Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography color="textSecondary">
          <FormattedMessage id="offer-car-features.color" />:
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>{offerCar?.color}</Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography color="textSecondary">
          <FormattedMessage id="offer-car-features.kms" />:
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>{offerCar?.kms ? formatKilometers(offerCar?.kms) : '-'}</Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography color="textSecondary">
          <FormattedMessage id="offer-car-features.box" />:
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>{offerCar?.box}</Typography>
      </Grid>
    </Grid>
  );
}

OfferCarFeatures.propTypes = {
  offerCar: PropTypes.object
};

export default OfferCarFeatures;
