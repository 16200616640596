// third-party
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: null,
  checkout: {
    step: 0,
    products: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
    payment: {
      type: 'free',
      method: 'card',
      card: ''
    }
  }
};

const cart = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // ADD PRODUCT
    addProduct(state, action) {
      const { products } = action.payload;
      const newItem = products[0];

      const existingItem = state.checkout.products.find((item) => item.id === newItem.id);
      if (!existingItem) {
        const newTotal = state.checkout.total + newItem.price;

        return {
          ...state,
          checkout: {
            ...state.checkout,
            products: [...state.checkout.products, newItem],
            total: newTotal
          }
        };
      }

      return state;
    },

    // REMOVE PRODUCT
    removeProduct(state, action) {
      const { id } = action.payload;

      const itemIndex = state.checkout.products.findIndex((item) => item.id == id);

      if (itemIndex !== -1) {
        const removedItem = state.checkout.products[itemIndex];
        const newTotal = state.checkout.total - removedItem.price;
        const updatedProducts = [...state.checkout.products.slice(0, itemIndex), ...state.checkout.products.slice(itemIndex + 1)];

        return {
          ...state,
          checkout: {
            ...state.checkout,
            products: updatedProducts,
            total: newTotal
          }
        };
      }

      return state;
    },

    // UPDATE PRODUCT
    updateProductSuccess(state, action) {
      state.checkout.products = action.payload.products;
      state.checkout.subtotal = state.checkout.subtotal - action.payload.oldSubTotal + action.payload.subtotal;
      state.checkout.total = state.checkout.total - action.payload.oldSubTotal + action.payload.subtotal;
    },

    // SET STEP
    setStepSuccess(state, action) {
      state.checkout.step = action.payload;
    },

    // SET NEXT STEP
    setNextStepSuccess(state) {
      state.checkout.step += 1;
    },

    // SET BACK STEP
    setBackStepSuccess(state) {
      state.checkout.step -= 1;
    },

    // SET BILLING ADDRESS
    setBillingAddressSuccess(state, action) {
      state.checkout.billing = action.payload.billing;
    },

    // SET DISCOUNT
    setDiscountSuccess(state, action) {
      let difference = 0;
      if (state.checkout.discount > 0) {
        difference = state.checkout.discount;
      }

      state.checkout.discount = action.payload.amount;
      state.checkout.total = state.checkout.total + difference - action.payload.amount;
    },

    // SET SHIPPING CHARGE
    setShippingChargeSuccess(state, action) {
      state.checkout.shipping = action.payload.shipping;
      state.checkout.total += action.payload.newShipping;
      state.checkout.payment = {
        ...state.checkout.payment,
        type: action.payload.type
      };
    },

    // RESET CART
    resetCardSuccess(state) {
      state.checkout = initialState.checkout;
    }
  }
});

// Reducer
export default cart.reducer;

export const { hasError, addProduct, removeProduct } = cart.actions;
