// project import
import useAuth from 'hooks/useAuth';
import { infoVertical, infoHorizontal } from 'menu-items/info';
import { salesVertical, salesHorizontal } from 'menu-items/sales';
import { purchasesVertical, purchasesHorizontal } from 'menu-items/purchases';

// ==============================|| MENU ITEMS ||============================== //

function useMenuConfig() {
  const { user } = useAuth();

  const verticalNavigation = {};
  const horizontalNavigation = {};

  if (user?.tags.length == 0) {
    verticalNavigation['info'] = {
      items: [infoVertical]
    };
    horizontalNavigation['info'] = infoHorizontal;
  } else {
    user?.tags.forEach((role) => {
      switch (role) {
        case 'Client VO':
          verticalNavigation['client'] = {
            items: [purchasesVertical]
          };
          break;
        case 'Provider':
          verticalNavigation['provider'] = {
            items: [salesVertical]
          };
          break;
        default:
          verticalNavigation['info'] = {
            items: [infoVertical]
          };
          break;
      }
    });

    user?.tags.forEach((role) => {
      switch (role) {
        case 'Client VO':
          horizontalNavigation['client'] = purchasesHorizontal;
          break;
        case 'Provider':
          horizontalNavigation['provider'] = salesHorizontal;
          break;
        default:
          horizontalNavigation['info'] = infoHorizontal;
          break;
      }
    });
  }

  return {
    verticalNavigation,
    horizontalNavigation
  };
}

export { useMenuConfig };
