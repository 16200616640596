import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';

// third party
import axios from 'axios';
import PropTypes from 'prop-types';

// action - state management
import authReducer from 'store/reducers/cano-auth';
import { LOGIN, LOGOUT } from 'store/reducers/actions';

// project import
import Loader from 'components/Loader';
import useConfig from 'hooks/useConfig';
import { activeRole } from 'store/reducers/menu';
import { clearCookies, getCookie } from 'api/CookieService';
import { getUser, login, logout, getUserLanguage } from 'api/UserService';

const BASE_URL = process.env.REACT_APP_API_HOST;
const AxiosInstance = axios.create({ baseURL: BASE_URL });

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const UserContext = createContext(null);

const UserContextProvider = ({ children }) => {
  const dispatchStore = useDispatch();
  const { onChangeLocalization } = useConfig();
  const [isAxiosSet, setIsAxiosSet] = useState(false);
  const [state, dispatch] = useReducer(authReducer, initialState);

  const getLoginPayload = (data) => ({
    user: {
      id: data.id,
      email: data.email,
      name: data.name || '',
      phone: data.phone || '',
      mobile: data.mobile || '',
      function: data.function || '',
      comment: data.comment || '',
      company: data.company || '',
      companyName: data.company_name || '',
      country: data.country || '',
      tags: data.tags || [],
      avatar: BASE_URL + '/api/v1/users/images'
    }
  });
  const getUserRole = (user) => {
    return user.tags.includes('Client VO') ? 'client' : user.tags.includes('Provider') ? 'provider' : 'info';
  };

  useEffect(() => {
    const onResponse = (response) => response;

    const onError = async (error) => {
      console.error('Error caught in AuthInterceptor', error);
      switch (error.response?.status) {
        case 401:
          await userLogout();
      }
      return Promise.reject(error);
    };

    const responseInterceptor = AxiosInstance.interceptors.response.use(onResponse, onError);
    setIsAxiosSet(true);

    return () => AxiosInstance.interceptors.response.eject(responseInterceptor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getUser();
      if (!result) {
        dispatch({ type: LOGOUT });
        return;
      }

      dispatch({ type: LOGIN, payload: getLoginPayload(result.data.data[0]) });
      dispatchStore(activeRole({ userRole: getUserRole(result.data.data[0]) }));
    };

    const auth = getCookie('auth');
    const cookiesConsent = getCookie('cookiesConsent');

    if (!auth || !cookiesConsent) {
      dispatch({ type: LOGOUT });
      return;
    }

    if (isAxiosSet) fetchData();
  }, [dispatch, isAxiosSet, dispatchStore]);

  const loadLang = async () => {
    const result = await getUserLanguage();
    if (result) {
      onChangeLocalization(result?.data?.data[0].lang);
    }
  };

  const userLogin = async (username, password) => {
    const loginResponse = await login({ username, password });
    if (loginResponse) {
      const result = await getUser();
      await loadLang();
      dispatch({ type: LOGIN, payload: getLoginPayload(result.data.data[0]) });
      dispatchStore(activeRole({ userRole: getUserRole(result.data.data[0]) }));
    }
  };

  const userLogout = async () => {
    clearCookies();
    await logout(); // delete cookie from backend, as it is httponly
    dispatch({ type: LOGOUT });
  };

  if (!isAxiosSet || !state.isInitialized) {
    return <Loader />;
  }

  return <UserContext.Provider value={{ ...state, login: userLogin, logout: userLogout }}>{children}</UserContext.Provider>;
};

UserContextProvider.propTypes = {
  children: PropTypes.node
};

export { UserContext, UserContextProvider, AxiosInstance };
