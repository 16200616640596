import { useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import NavItem from './NavItem';
import useConfig from 'hooks/useConfig';
import { useMenuConfig } from 'hooks/useMenuConfig';
import { HORIZONTAL_MAX_ITEM, LAYOUT_CONST } from 'config';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const HorizontalNavigation = () => {
  const theme = useTheme();
  const { horizontalNavigation } = useMenuConfig();

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuOrientation } = useConfig();
  const { drawerOpen, userRole } = useSelector((state) => state.menu);
  const [selectedItems, setSelectedItems] = useState('');
  const [selectedLevel, setSelectedLevel] = useState(0);

  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
  let lastItemIndex = horizontalNavigation[userRole].items.length - 1;
  let remItems = [];
  let lastItemId;

  if (lastItem && lastItem < horizontalNavigation[userRole].items.length) {
    lastItemId = horizontalNavigation[userRole].items[lastItem - 1].id;
    lastItemIndex = lastItem - 1;
    remItems = horizontalNavigation[userRole].items.slice(lastItem - 1, horizontalNavigation[userRole].items.length).map((item) => ({
      title: item.title,
      elements: item.children,
      icon: item.icon
    }));
  }

  const navGroups = horizontalNavigation[userRole].items.slice(0, lastItemIndex + 1).map((item) => {
    switch (item.type) {
      case 'group':
        return (
          <NavGroup
            key={item.id}
            setSelectedItems={setSelectedItems}
            setSelectedLevel={setSelectedLevel}
            selectedLevel={selectedLevel}
            selectedItems={selectedItems}
            lastItem={lastItem}
            remItems={remItems}
            lastItemId={lastItemId}
            item={item}
          />
        );
      default:
        return <NavItem key={item.id} item={item} level={1} />;
    }
  });
  return (
    <Box
      sx={{
        minHeight: '48px',
        pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
        '& > ul:first-of-type': { mt: 0 },
        display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block'
      }}
    >
      {navGroups}
    </Box>
  );
};

export default HorizontalNavigation;
