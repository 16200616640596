import { apiGet, apiPut } from '.';

export const putNotification = async (body, id) => {
  const url = `/api/v1/notifications/${id}`;
  return apiPut(url, body);
};

export const getNotifications = async () => {
  const url = '/api/v1/notifications';

  const res = await apiGet(url);
  return res;
};
