import { useState } from 'react';
import PropTypes from 'prop-types';

// third-party
import { FormattedMessage, useIntl } from 'react-intl';

// material-ui
import {
  Box,
  DialogContent,
  DialogTitle,
  Typography,
  Divider,
  DialogActions,
  Button,
  Grid,
  List,
  CardMedia,
  ListItemButton,
  ListItemText,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';

import useUtils from 'hooks/useUtils';
import formattedPrice from 'utils/formatPrice';
import { removeProduct } from 'store/reducers/cart';
import { useDispatch } from 'store';
import { putAcceptOffer } from 'api/ListVoOfferService';
import getCarPhoto from 'utils/carPhotos';

// ==============================|| CONFIRM DIALOG ||============================== //

export default function AcceptOfferDialog({ closeDialog, onAccept, listVoCarsData, isShoppingCart = false }) {
  // sx styles
  const actionSX = {
    mt: '6px',
    ml: 1,
    top: 'auto',
    transform: 'none'
  };

  const intl = useIntl();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { displaySnackbar } = useUtils();

  const onSubmit = async () => {
    setLoading(true);
    const data = listVoCarsData.map((car) => ({
      offer_id: car.offer_id,
      id: car.id
    }));

    try {
      await putAcceptOffer({ list_vo_offer_info: data });
      displaySnackbar(intl.formatMessage({ id: 'message-your-request-registered' }));
      onAccept();
    } catch (error) {
      displaySnackbar(intl.formatMessage({ id: 'message-something-went-wrong' }), 'error');
      console.error(error);
    }
  };

  const handleDelete = (event) => {
    const carToDelete = event?.currentTarget.id;
    dispatch(removeProduct({ id: carToDelete }));
  };

  const totalPrice = listVoCarsData.reduce((acc, car) => acc + car.price, 0);

  const imageWidth = isShoppingCart ? 220 : 200;
  const carDescriptionWidth = isShoppingCart ? 'calc(6/10*100%)' : 'calc(8/10*100%)';
  const carDescriptionMaxWidth = isShoppingCart ? 250 : 300;
  const carPriceWidth = isShoppingCart ? 'calc(2/10*100%)' : 'calc(1/10*100%)';

  return (
    <Box sx={{ p: 1, py: 1.5 }}>
      <DialogTitle sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
        <Typography sx={{ textTransform: 'uppercase', fontWeight: '700', fontSize: '1.2rem' }}>
          <FormattedMessage id="your-order" />
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 1, pl: 3, pr: 3 }} style={{ overflow: 'auto', maxHeight: '32vh' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            {listVoCarsData.length ? (
              <List
                sx={{
                  py: 0,
                  '& .MuiListItemButton-root': {
                    '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                  }
                }}
              >
                {listVoCarsData.map((car, index) => (
                  <ListItemButton
                    divider={index < listVoCarsData.length - 1}
                    key={index}
                    sm={12}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Box sx={{ width: imageWidth, m: 'auto', p: 1 }}>
                      <CardMedia sx={{ height: 75, textDecoration: 'none', opacity: 1 }} image={getCarPhoto(car.brand)} />
                    </Box>
                    <ListItemText
                      primary={<Typography variant="subtitle1">{`${car.brand} ${car.model}`}</Typography>}
                      secondary={
                        <Typography variant="body2" color="text.secondary">
                          {car.version}
                        </Typography>
                      }
                      sx={{ width: carDescriptionWidth, maxWidth: carDescriptionMaxWidth }}
                    />
                    <ListItemText
                      primary={<Typography variant="subtitle1">{`${car.price !== null ? formattedPrice(car.price) : '--'}`}</Typography>}
                      secondary={
                        <Typography variant="body2" color="text.secondary">
                          <FormattedMessage id="price" />
                        </Typography>
                      }
                      sx={{ width: carPriceWidth, pl: 1 }}
                    />
                    {isShoppingCart && (
                      <IconButton id={car.id} edge="end" aria-label="delete" onClick={(e) => handleDelete(e)} size="small">
                        <DeleteIcon color="secondary" />
                      </IconButton>
                    )}
                  </ListItemButton>
                ))}
              </List>
            ) : (
              <p>
                {' '}
                <FormattedMessage id="no-cars" />
              </p>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <Box sx={{ p: 2, pb: 0, display: 'flex', justifyContent: 'flex-end' }}>
        <Typography variant="h5" sx={{ fontWeight: '700' }}>
          <FormattedMessage id="total" />
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: '500', pl: 1, pr: 1 }} color="secondary">
          {`(${listVoCarsData.length} ${
            listVoCarsData.length === 1
              ? intl.formatMessage({ id: 'accept-offer-dialog.car' })
              : intl.formatMessage({ id: 'accept-offer-dialog.cars' })
          })`}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: '700' }}>
          :&nbsp;&nbsp;{formattedPrice(totalPrice)}
        </Typography>
      </Box>
      <DialogActions sx={{ p: 2.5 }}>
        {!isShoppingCart && (
          <Button color="warning" onClick={closeDialog}>
            <FormattedMessage id="back" />
          </Button>
        )}
        <LoadingButton disabled={listVoCarsData.length === 0} onClick={onSubmit} type="submit" variant="contained" loading={loading}>
          <FormattedMessage id="place-order" />
        </LoadingButton>
      </DialogActions>
    </Box>
  );
}

AcceptOfferDialog.propTypes = {
  open: PropTypes.bool,
  isShoppingCart: PropTypes.bool,
  closeDialog: PropTypes.func,
  onAccept: PropTypes.func,
  listVoCarsData: PropTypes.array
};
