export const getCookie = (cookieName) => {
  const cookies = document.cookie.split(';');
  const item = cookies.find((cookie) => cookie.trim().split('=')[0] === cookieName);
  return item?.split('=')[1] || '';
};

export const clearCookies = () => {
  const cookies = document.cookie.split(';');
  const allCookieNames = cookies.map((cookie) => cookie.split('=')[0]);
  const filteredCookieNames = allCookieNames.filter((element) => element !== 'cookiesConsent');
  const expiration = '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  filteredCookieNames.forEach((cookie) => (document.cookie = `${cookie}${expiration}`));
};
