import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, ClickAwayListener, Grid, List, ListItemButton, ListItemText, Paper, Popper, Typography, useMediaQuery } from '@mui/material';

// project import
import useAuth from 'hooks/useAuth';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import { activeRole } from 'store/reducers/menu';
import { useMenuConfig } from 'hooks/useMenuConfig';

// assets
import { AppstoreOutlined } from '@ant-design/icons';

// ==============================|| HEADER CONTENT - LOCALIZATION ||============================== //

const Application = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useAuth();
  const { horizontalNavigation } = useMenuConfig();
  const { userRole, openItems } = useSelector((state) => state.menu);

  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const getUrlById = (role) => {
    const item = horizontalNavigation[role].items.find((item) => item.id === openItems[role][0]);
    return item ? item.url : null;
  };

  const handleListItemClick = (role) => {
    dispatch(activeRole({ userRole: role }));
    const url = getUrlById(role);
    navigate(url);
    setOpen(false);
  };

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label="open localization"
        ref={anchorRef}
        aria-controls={open ? 'localization-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <AppstoreOutlined />
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom-start' : 'bottom'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 0 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position={matchesXs ? 'top-right' : 'top'} in={open} {...TransitionProps}>
            <Paper sx={{ boxShadow: theme.customShadows.z1 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <List
                  component="nav"
                  sx={{
                    p: 0,
                    width: '100%',
                    minWidth: 200,
                    maxWidth: 290,
                    bgcolor: theme.palette.background.paper,
                    borderRadius: 0.5,
                    [theme.breakpoints.down('md')]: {
                      maxWidth: 250
                    }
                  }}
                >
                  {(user.tags.includes('Client VO') || user.tags.includes('devepolment')) && (
                    <ListItemButton selected={userRole === 'client'} onClick={() => handleListItemClick('client')}>
                      <ListItemText
                        primary={
                          <Grid container>
                            <Typography color="textPrimary">
                              <FormattedMessage id="client" />
                            </Typography>
                          </Grid>
                        }
                      />
                    </ListItemButton>
                  )}
                  {/* {user.tags.includes('Transport') && (
                    <ListItemButton selected={userRole === 'transport'} onClick={() => handleListItemClick('transport')}>
                      <ListItemText
                        primary={
                          <Grid container>
                            <Typography color="textPrimary">Transport</Typography>
                          </Grid>
                        }
                      />
                    </ListItemButton>
                  )} */}
                  {(user.tags.includes('Provider') || user.tags.includes('devepolment')) && (
                    <ListItemButton selected={userRole === 'provider'} onClick={() => handleListItemClick('provider')}>
                      <ListItemText
                        primary={
                          <Grid container>
                            <Typography color="textPrimary">
                              <FormattedMessage id="provider" />
                            </Typography>
                          </Grid>
                        }
                      />
                    </ListItemButton>
                  )}
                </List>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Application;
