// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';

// auth provider
import { UserContextProvider as AuthProvider } from 'contexts/UserContext';
import { UtilsProvider } from 'contexts/UtilsContext';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (
  <ThemeCustomization>
    {/* <RTLLayout> */}
    <UtilsProvider>
      <AuthProvider>
        <Locales>
          <ScrollTop>
            <>
              <Routes />
              <Snackbar />
            </>
          </ScrollTop>
        </Locales>
      </AuthProvider>
    </UtilsProvider>
    {/* </RTLLayout> */}
  </ThemeCustomization>
);

export default App;
