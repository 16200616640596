// third-party
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'reduxjs-toolkit-persist';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';

// project import
import menu from './reducers/menu';
import cart from './reducers/cart';
import snackbar from './reducers/snackbar';
import calendar from './reducers/calendar';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['menu']
};

const menuPersistConfig = {
  key: 'menu',
  storage,
  blacklist: ['openItem', 'menuDashboard', 'openComponent']
};

const rootReducer = combineReducers({
  menu: persistReducer(menuPersistConfig, menu),
  snackbar,
  calendar,
  cart
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

const persistor = persistStore(store);

const { dispatch } = store;

const useDispatch = () => useAppDispatch();
const useSelector = useAppSelector;

export { store, persistor, dispatch, useSelector, useDispatch };
